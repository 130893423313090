@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.text-care{
	color: #89764b;
}

.bg-care{
	background-color: #89764b;
}

.bg-care-light {
	background-color: #a68b63;
  }
  
  .bg-care-dark {
	background-color: #5e4e31;
  } 


.bg-custom-grey{
	background-color: #212121;
}

.bg-custom-lightgrey{
	background-color: #616161;
}

.glassmorphism{
	background: rgba(0, 0, 0, 0.2);
	z-index: 1000;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(15px);
	-webkit-backdrop-filter: blur(30px);
	border: 0px solid rgba(255, 255, 255, 0.3);
}

.glassmorphism-white{
	background: rgba(255, 255, 255, 0.2);
	z-index: 1000;
	box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(15px);
	-webkit-backdrop-filter: blur(30px);
	border: 1px solid rgba(0, 0, 0, 0.3);
}

/* styles.css */
.confetti-canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999; /* Ensure this is high enough to be on top of all other elements */
	pointer-events: none; /* Prevent interactions with the canvas */
}

.text-negative {
    color: rgb(255, 255, 255);
    display: block;
    filter: invert(0.4);
    mix-blend-mode: difference;
  }

  .text-negative-remove {
    color: black !important;
    display: block !important;
    filter: invert(0) !important;
    mix-blend-mode: normal !important;
  }
